const Texts = {
	Navigation: {
		Title: "Synamedia",
		Solutions: "Solutions",
		Services: "Services & Support",
		ManagementTools: "Management Tools",
		AddNewFile: "Add new file",
		Categories: "Categories",
		ReturnToSynamedia: "Synamedia.com",
		Login: "Login",
		Logout: "Logout",
		LoginInternalUser: "Internal user",
		LoginExternalUser: "External user",
		Back: "Back",
	},
	HomeView: {
		AppTitle: "Software and Documentation",
		AppSubtitle: "Synamedia product resources",
		SolutionsTitle: "Solutions",
		ServicesTitle: "Services & Support",
		ServicesLinks: {
			SupportTicketsLink: "Support Tickets",
			EoLNoticesLink: "End of Life Notices",
			FieldNoticesLink: "Field Notices",
			ContactsLink: "Contacts",
			OpenSourceLink: "Open Source",
			QuickStartGuidesLink: "Quick Start Guides",
		},
	},
	SolutionsView: {
		UnauthorizedAccess: "Please log in to access this content",
		ViewTitle: "Software and Documentation",
		Tabs: {
			AddNewCategory: "Add new category",
			AddButton: "Add",
			CancelButton: "Cancel",
		},
		Browser: {
			Tabs: {
				Software: "Software",
				Documentation: "Documentation",
				Training: "Training",
			},
			TreeItemTooltips: {
				AddNew: "Add new category",
				Upload: "Upload file",
				Edit: "Edit category",
				Delete: "Delete",
			},
			ItemDetailAttributes: {
				Download: "Download",
				Title: "Title",
				Description: "Description",
				Updated: "Updated",
				FileSize: "File size",
				mdChecksum: "MD5 Checksum",
				shaChecksum: "SHA 256 Checksum",
			},
		},
	},
	ContactsView: {
		Title: "Global and Local Contact Numbers",
		VideoNetworksTitle: "Video Networks",
		RegionalNumbersTitle: "Regional TAC Numbers",
	},
  OpensourceView: {
    Title: "Open Source Notices",
    Disclaimer: "Synamedia values the open source community as an essential resource and partner in innovation. Here you can find information on the open source used in Synamedia products.",
    ContactMailTo: "mailto:external-opensource-requests@synamedia.com?subject=Open%20Source%20Code%20Request",
    ContactLinkText: "Open Source Requests",
  },
	SolutionGroups: {
		VideoNetwork: "Video Network",
		VideoPlatform: "Video Platform",
		VideoControlPlane: "Video Control Plane",
		LegacySoftware: "Legacy Software",
		SupportTickets: "Support Tickets",
		EOL: "End of Life Notices",
		FieldNotices: "Field Notices",
		Contacts: "Contacts",
		OpenSource: "Open Source",
		QuickStartQuides: "Quick Start Guides",
	},
	FileManagementView: {
		AddNewFormTitle: "Add a new file",
		EditFormTitle: "Edit file metadata",
		Sections: {
			Upload: {
				Title: "Select a file & upload",
				SelectButton: "Select File...",
				UploadButton: "Upload",
				UploadFinished: "Uploaded Successfully",
				Progress: {
					Processing: "Processing",
					Uploading: "Uploading",
					Finished: "Finished",
					Failed: "Something went wrong",
				},
			},
			Metadata: {
				Title: "Metadata",
				TitleColumn: {
					Title: "Title (User-Friendly)",
					DescriptionFieldLabel: "Description",
				},
				VersionColumn: {
					Title: "Version",
					MajorFiledLable: "Major",
					MinorFieldLabel: "Minor",
					PatchFieldLabel: "Patch",
				},
				TypeColumn: {
					Title: "File Type",
				},
				InfoColumn: {
					Title: "Download information",
					FieldTitles: {
						MdChecksum: "MD5 Checksum",
						Size: "Size",
						UploadedBy: "Uploaded by",
						TimeStamp: "Time stamp",
					},
				},
			},
			Audience: {
				Title: "Select Audience",
				PermissionsColumn: {
					Title: "Security permissions",
					Options: {
						Registered: "Private - select companies",
						RegisteredAll: "Private - any logged in user",
						Public: "Public",
					},
				},
				SelectCompanyColumn: {
					Title: "Find & select company names",
					GuideText1: "Search for each company by name then check the box to add to the Target Audience List",
					GuideText2: "OR choose a role",
				},
				TargetAudience: {
					Title: "Target Audience",
					GuideText: "Companies who have access when logged in.",
				},
			},
			Taxonomy: {
				Title: "Taxonomy",
				GuideText:
					"Choose the location for this file. Version number categories are created automatically based upon the major & minor version number entered in the metadata section. If no version number is entered, the file will be appear as 'Latest' in the selected category below.",
				CollapseAll: "Collapse All",
			},
		},
	},
	CategoryManagementView: {
		ViewTitle: "Category Management",
		SelectButton: "Select Icon",
		AddButton: "Add",
	},
	Dialogs: {
		Download: {
			Title: "Download files",
			ContextText: "Download selected files ",
			FileNameColumn: "File name",
			FileLinkColumn: "File link column",
		},
		AddNewFolder: {
			Title: "Add new folder",
			NameFieldLable: "Folder name",
		},
		DeleteConfirmation: {
			Title: "Delete",
			ContextText: "Are you sure you want to delete ",
		},
		PublicPermissionConfirmation: {
			ContextText: "You have marked this file for public access.",
			ConfirmText: "Are you sure about this?",
		},
	},
	Buttons: {
		Cancel: "Cancel",
		Upload: "Upload",
		Submit: "Submit",
		Save: "Save",
		Edit: "Edit",
		DownloadSelected: "Download selected",
		DownloadAll: "Download all",
		Add: "Add",
		Delete: "Delete",
		ConfirmPublic: "Confirm public",
		RevertToPublic: "Revert to Private",
	},
	Footer: {
		ConditionsNotice:
			'The Synamedia software and documentation ("Materials") available on software.synamedia.com ("Site") is the confidential information of Synamedia. Use of the Materials will be subject to the terms and conditions of your license or other agreement that you have with Synamedia ("Agreement"). The Materials must only be used or downloaded and installed or distributed in accordance with your Agreement. Any other use of the Materials is not permitted. The availabillity of the Materials on and your access to the Site does not imply any right to use in the absence of an Agreement.',
	},
};

export default Texts;

import * as React from "react";
import { Route as WrongRoute, Switch as WrongSwitch } from "react-router-dom";
import Layout from "./components/Layout";
import RoleRestrictedComponent from "./components/shared/formControls/RoleRestrictedComponent";
import { Routing } from "./resources/Routes";
import "./styles/custom.scss";
import FileManagementView from "./views/fileManagement/FileManagementView";
import HomeView from "./views/homeView/HomeView";
import SolutionsView from "./views/solutionsView/SolutionsView";
import ContactsView from "./views/contacts/ContactsView";
import OpensourceView from "./views/opensource/OpensourceView";
import CategoryManagementView from "./views/categoryManagement/CategoryManagementView";

const Switch = WrongSwitch as any;
const Route = WrongRoute as any;

const App: React.FunctionComponent<{}> = () => {
	return (
		<Layout>
			<Switch>
				<Route exact path={Routing.Routes.HomePage} component={HomeView} />
				<Route exact path={Routing.Routes.Solutions} component={SolutionsView} />
				<Route exact path={Routing.Routes.Contacts} component={ContactsView} />
				<Route exact path={Routing.Routes.Opensource} component={OpensourceView} />


				<RoleRestrictedComponent enabledRoles={["Engineer"]}>
					<Route
						exact
						path={Routing.Routes.FileManagement}
						component={FileManagementView}
					/>

					<Route exact path={Routing.Routes.NewFile} component={FileManagementView} />

					<Route
						exact
						path={Routing.Routes.CategoryManagement}
						component={CategoryManagementView}
					/>
				</RoleRestrictedComponent>
			</Switch>
		</Layout>
	);
};

export default App;

export enum SolutionsGroup {
	VideoNetwork = "Synamedia|Video Network",
	VideoPlatform = "Synamedia|Video Platform",
	VideoControlPlane = "VCON",
	LegacySoftware = "Cisco",
	EOL = "EOLNotices",
	FieldNotices = "FieldNotices",
	//OpenSource = "OSNotices",
	//QuickStartQuides = "QuickStarts",
}

export enum FileType {
	Software = "Software",
	Documentation = "Documentation",
	Training = "Training",
}

export enum FilePermission {
	Registered = "Registered",
	RegisteredAll = "RegisteredAll",
	Public = "Public",
}

import { Close, Delete, Edit, FileDownloadOutlined, InfoOutlined } from "@mui/icons-material";
import { CircularProgress, Collapse, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
	IDetailFileMetadata,
	IFileDownloadData,
	ISolutionTreeItem,
} from "../../resources/Contracts";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { ApplicationState } from "../../store";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import * as SelectedSolutionStore from "../../store/SelectedSolution";
import { formatFileSize, handleErrorMessage } from "../../utils/utils";
import RoleRestrictedComponent from "../shared/formControls/RoleRestrictedComponent";
import DeleteConfirmationDialog from "../dialogs/DeleteConfirmationDialog";
import "./VersionRecord.scss";

type VersionRecordProps = SelectedSolutionStore.SelectedSolutionState &
	typeof AlertStoreActionCreators & {
		record: ISolutionTreeItem;
		onDelete: (id: string) => void;
	};

const VersionRecord: React.FC<VersionRecordProps> = (props) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const [fileMetadata, setFileMetadata] = React.useState<IDetailFileMetadata>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

	const handleDownloadClick = async () => {
		try {
			const fileDownloadData: IFileDownloadData = await Service.getFileDownloadLink(
				props.record.id
			);

			const aElement: HTMLAnchorElement = document.createElement("a");
			aElement.href = fileDownloadData.fileLink;
			aElement.download = fileDownloadData.fileName;

			document.body.appendChild(aElement);

			aElement.click();
			aElement.remove();
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
		}
	};

	const handleOpenClick = async () => {
		if (!open && !fileMetadata) {
			setLoading(true);

			try {
				const resultFileMetadata: IDetailFileMetadata = await Service.getFileDetailMetadata(
					props.record.id
				);

				setFileMetadata(resultFileMetadata);
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}

			setLoading(false);
		}

		setOpen(!open);
	};

	const handleDeleteConfirmed = () => {
		props.onDelete(props.record.id);
	};

	const className: string = open ? "versionRecord open" : "versionRecord";

	return (
		<Box display="flex" flexDirection="column" className={className}>
			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box className="versionRecord-title" fontFamily="Source Sans Pro">
					{props.record.text}

					{loading && (
						<CircularProgress
							className="progress"
							size={15}
							sx={{ marginLeft: "5px" }}
						/>
					)}
				</Box>

				<Box display="flex" flexDirection="row" alignItems="center">
					<RoleRestrictedComponent enabledRoles={["Engineer"]}>
						<Link
							to={Routing.getFileManagementUrl({
								parentId: props.selectedSolution.id,
								from: props.selectedGroup,
								fromId: props.selectedGroupId,
								fileId: props.record.fileUniqueId,
								prevTabId: props.selectedSolutionsTabId
							})}
						>
							<IconButton className="versionRecord-icon" component="span">
								<Edit />
							</IconButton>
						</Link>
					</RoleRestrictedComponent>

					<RoleRestrictedComponent enabledRoles={["Engineer"]}>
						<IconButton
							className="versionRecord-icon"
							component="span"
							onClick={() => setDeleteDialogOpen(true)}
						>
							<Delete />
						</IconButton>
					</RoleRestrictedComponent>

					<IconButton
						className="versionRecord-icon"
						component="span"
						onClick={handleDownloadClick}
					>
						<FileDownloadOutlined />
					</IconButton>

					{!open && (
						<IconButton
							className="versionRecord-icon"
							component="span"
							onClick={handleOpenClick}
						>
							<InfoOutlined />
						</IconButton>
					)}

					{open && (
						<IconButton
							className="versionRecord-icon"
							component="span"
							onClick={handleOpenClick}
						>
							<Close />
						</IconButton>
					)}
				</Box>
			</Box>

			<Collapse in={open} unmountOnExit>
				<Box display="flex" flexDirection="column">
					<AttributeValue
						value={fileMetadata?.title}
						label={Texts.SolutionsView.Browser.ItemDetailAttributes.Title}
					/>

					<AttributeValue
						value={fileMetadata?.description}
						label={Texts.SolutionsView.Browser.ItemDetailAttributes.Description}
					/>

					<AttributeValue
						value={
							fileMetadata?.timeStamp &&
							format(new Date(fileMetadata.timeStamp), "dd/MM/yyyy hh:mm:ss a")
						}
						label={Texts.SolutionsView.Browser.ItemDetailAttributes.Updated}
					/>

					<AttributeValue
						value={fileMetadata?.size && formatFileSize(fileMetadata.size)}
						label={Texts.SolutionsView.Browser.ItemDetailAttributes.FileSize}
					/>

					<AttributeValue
						value={fileMetadata?.mD5Checksum}
						label={Texts.SolutionsView.Browser.ItemDetailAttributes.mdChecksum}
					/>
				</Box>
			</Collapse>

			<DeleteConfirmationDialog
				open={deleteDialogOpen}
				itemName={props.record.text}
				onDelete={handleDeleteConfirmed}
				close={() => setDeleteDialogOpen(false)}
			/>
		</Box>
	);
};

interface AttributeValueProps {
	value: string;
	label: string;
}

const AttributeValue: React.FC<AttributeValueProps> = (props) => {
	return (
		<Box display="flex" flexDirection="row">
			<Box display="flex" flex={2} sx={{ fontWeight: "bold" }} fontFamily="Poppins">
				{props.label}:
			</Box>

			<Box display="flex" flex={5} fontFamily="Poppins">
				{props.value}
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.selectedSolutions,
	mapDispatchToProps
)(VersionRecord);

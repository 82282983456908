import * as React from "react";
//import DownloadIcon from "@mui/icons-material/Download";
import { CircularProgress, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HomePageLink } from "../../components/homePageLink/HomePageLink";
import { ReactComponent as ContactsIcon } from "../../images/icons/icon-contacts.svg";
import { ReactComponent as EolNoticeIcon } from "../../images/icons/icon-eol-notice.svg";
import { ReactComponent as FieldNoticesIcon } from "../../images/icons/icon-field-notices.svg";
//import { ReactComponent as GuidesIcon } from "../../images/icons/icon-guides.svg";
import { ReactComponent as OpensourceIcon } from "../../images/icons/icon-opensource.svg";
import { ReactComponent as SupportTicketIcon } from "../../images/icons/icon-support-ticket.svg";
import * as Contracts from "../../resources/Contracts";
import { SolutionsGroup } from "../../resources/Enums";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import { Service } from "../../services/Service";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { handleErrorMessage, redirectToSupport } from "../../utils/utils";
import "./HomeView.scss";

type HomeViewProps = typeof AlertStoreActionCreators & {};

const HomeView: React.FunctionComponent<HomeViewProps> = ({ addErrorAlert }) => {
	const [solutionCategories, setSolutionCategories] = React.useState<Contracts.ICategory[]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	// group IDs
	const [eolIdNoticesId, setEolIdNoticesId] = React.useState<number>(0);
	const [fieldNoticesId, setFieldNoticesId] = React.useState<number>(0);

	const loadCategories = React.useCallback(async (): Promise<void> => {
		setLoading(true);

		try {
			const responseItems: Contracts.ICategory[] = await Service.getCategories();

			setSolutionCategories(responseItems);
		} catch (error) {
			addErrorAlert(handleErrorMessage(error));
		}

		setLoading(false);
	}, [addErrorAlert]);

	const loadGroupIds = React.useCallback(async (): Promise<void> => {
		await Service.getGroupNavigationNodeId(SolutionsGroup.EOL)
			.then((result) => {
				if (!isNaN(result))
					setEolIdNoticesId(result);
			});
		await Service.getGroupNavigationNodeId(SolutionsGroup.FieldNotices)
			.then((result) => {
				if (!isNaN(result))
					setFieldNoticesId(result);
			});
	}, []);

	React.useEffect(() => {
		loadCategories();
		loadGroupIds();
	}, [loadCategories, loadGroupIds]);

	return (
		<Box className="homeView">
			<Box
				className="homeView-header"
				display="flex"
				flexDirection="column"
				justifyContent="flex-end"
				alignItems="flex-end"
			>
				<Box className="homeView-title-box">
					<Box
						className="homeView-app-title-container"
						display="flex"
						flexDirection="column"
					>
						<Typography variant="h3" component="div" fontFamily="Poppins" fontWeight="700">
							{Texts.HomeView.AppTitle}
						</Typography>

						<Box className="homeView-app-subtitle" display="flex" alignItems="center">
							<Typography
								variant="subtitle1"
								component="div"
								fontFamily="Poppins"
							>
								{Texts.HomeView.AppSubtitle}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="homeView-solutionsLinks-wrapper">
				<Container>
					<Typography
						variant="h5"
						component="div"
						className="homeView-solutionsLinks-title"
						fontFamily="Poppins"
					>
						{Texts.HomeView.SolutionsTitle}
					</Typography>

					<Box
						className="homeView-solutionsLinks"
						display="flex"
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
					>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								{solutionCategories.map((c, index) => {
									let icon: string;

									if (c.icon) {
										if (c.icon.startsWith("data:")) {
											const splitIconValue = c.icon.split(",");

											icon = splitIconValue[splitIconValue.length - 1];
										} else {
											icon = c.icon;
										}
									}

									return (
										<HomePageLink
											key={`solutionLink-${index}`}
											className="homeView-solutionsLink"
											title={c.navigationName}
											icon={
												c.icon ? (
													<img src={`data:image/svg+xml;data:image/png;base64, ${icon}`} alt=""/>
												) : (
													<Box height="40px"></Box>
												)
											}
											url={Routing.getSolutionsViewUrl({
												group: c.navigationName,
												id: c.id,
											})}
										/>
									);
								})}
							</>
						)}
					</Box>
				</Container>
			</Box>

			<Box className="homeView-servicesLinks-wrapper">
				<Container>
					<Typography
						variant="h5"
						component="div"
						className="homeView-servicesLinks-title"
						fontFamily="Poppins"
					>
						{Texts.HomeView.ServicesTitle}
					</Typography>

					<Box
						className="homeView-servicesLinks"
						display="flex"
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
					>
						<HomePageLink
							className="homeView-servicesLink"
							title={Texts.HomeView.ServicesLinks.SupportTicketsLink}
							icon={<SupportTicketIcon className="icon" />}
							onClick={redirectToSupport}
						/>

						<HomePageLink
							className="homeView-servicesLink"
							title={Texts.HomeView.ServicesLinks.EoLNoticesLink}
							icon={<EolNoticeIcon className="icon" />}
							url={Routing.getSolutionsViewUrl({
								group: SolutionsGroup.EOL,
								id: eolIdNoticesId
							})}
						/>

						<HomePageLink
							className="homeView-servicesLink"
							title={Texts.HomeView.ServicesLinks.FieldNoticesLink}
							icon={<FieldNoticesIcon className="icon" />}
							url={Routing.getSolutionsViewUrl({
								group: SolutionsGroup.FieldNotices,
								id: fieldNoticesId
							})}
						/>

						<HomePageLink
							className="homeView-servicesLink"
							title={Texts.HomeView.ServicesLinks.ContactsLink}
							icon={<ContactsIcon className="icon" />}
							url={Routing.getContactsViewUrl()}
						/>

						<HomePageLink
							className="homeView-servicesLink"
							title={Texts.HomeView.ServicesLinks.OpenSourceLink}
							icon={<OpensourceIcon className="icon" />}
							url={Routing.getOpensourceViewUrl()}
						/>
					
					</Box>
				</Container>
			</Box>
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(null, mapDispatchToProps)(HomeView);

import * as React from "react";
import { FileManagementViewRouteParameters } from "../../resources/RouteParams";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Texts from "../../resources/Texts";
import "./FileManagementContainer.scss";
import FileUploadSection from "./fileUploadSection/FileUploadSection";
import FileMetadataSection from "./fileMetadataSection/FileMetadataSection";
import FileAudienceSection from "./fileAudienceSection/FileAudienceSection";
import FileTaxonomySection from "./fileTaxonomySection/FileTaxonomySection";
import * as FileManagementStore from "../../store/FileManagement";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import { actionCreators as AlertStoreActionCreators } from "../../store/AlertStore";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { handleErrorMessage } from "../../utils/utils";
import { Service } from "../../services/Service";
import { parseViewParameters } from "../../utils/viewUtils";
import { useLocation } from "react-router-dom";
import { IFileDetailResponse } from "../../resources/Contracts";
import { IFileVersion } from "../../resources/Contracts";
import { FilePermission, FileType } from "../../resources/Enums";
import { Routing } from "../../resources/Routes";

type FileManagementContainerProps = FileManagementViewRouteParameters &
	typeof FileManagementStore.actionCreators &
	typeof AlertStoreActionCreators &
	FileManagementStore.FileManagementState & {};

const FileManagementContainer: React.FunctionComponent<FileManagementContainerProps> = (props) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [fieldsVisible, setFieldsVisible] = React.useState<boolean>(false);
	const [updateInProgress, setUpdateInProgress] = React.useState<boolean>(false);

	const history = useHistory();
	const viewParameters = parseViewParameters<FileManagementViewRouteParameters>(
		useLocation().search
	);

	React.useEffect(() => {
		let isMounted: boolean = true;

		if (props.fileId) {
			const loadFileDetails = async (): Promise<void> => {
				setLoading(true);

				try {
					const responseItem: IFileDetailResponse =
						await Service.getFileDetailsByUniqueId(props.fileId);

					const responseItemType: FileType = FileType[responseItem.type];
					const responseItemVersion: IFileVersion = responseItem.version
						? {
								major: parseInt(responseItem.version.split(".")[0]),
								minor: parseInt(responseItem.version.split(".")[1]),
								patch: parseInt(responseItem.version.split(".")[2]),
						  }
						: undefined;

					if (isMounted) {
						props.setFileManagementFile({
							...props.fileData,
							parentId: props.parentId,
							fileName: responseItem.name,
							metadata: {
								...props.fileData?.metadata,
								description: responseItem.description,
								title: responseItem.title,
								fileType: responseItemType,
								version: responseItemVersion,
								downloadInfo: {
									...props.fileData?.metadata?.downloadInfo,
									fileUniqueId: responseItem.fileUniqueId,
									mD5Checksum: responseItem.mD5Checksum,
									size: responseItem.size,
									uploadedBy: responseItem.uploadedBy,
									timeStamp: responseItem.timeStamp,
								},
							},
							permission: FilePermission[responseItem.permission],
							permittedCompanies: responseItem.permittedCompanies,
							permittedRoles: responseItem.permittedRoles,
						});
					}
				} catch (error) {
					props.addErrorAlert(handleErrorMessage(error));
				}

				setLoading(false);
			};

			loadFileDetails();
			setFieldsVisible(true);
		} else if (props.parentId) {
			props.setFileManagementFile({
				...props.fileData,
				parentId: props.parentId,
			});
		}

		return () => {
			isMounted = false;

			props.setFileManagementFile(null);
		};
	}, []);

	function goBack(): void {
		if (viewParameters.from) {
			history.push(
				Routing.getSolutionsViewUrl({
					group: viewParameters.from,
					selectedNodeId: viewParameters.parentId,
					selectedTabId: viewParameters.prevTabId,
					id: viewParameters.fromId,
				})
			);
		} else {
			history.goBack();
		}
	}

	const title: string = props.fileId
		? Texts.FileManagementView.EditFormTitle
		: Texts.FileManagementView.AddNewFormTitle;

	const handleSubmitClick = async (): Promise<void> => {
		setUpdateInProgress(true);

		try {
			let version: string = "";

			if (!props.fileData?.metadata?.title) {
				throw new Error("Title is required");
			}

			if (!props.fileData?.metadata?.description) {
				throw new Error("Description is required");
			}

			if (props.fileData?.permission && props.fileData?.permission === 'Registered' &&
				!props.fileData?.permittedCompanies && !props.fileData?.permittedRoles) {
				throw new Error("You must specify a company(s) or role under 'Select Audience'");
			}

			if (
				!props.fileData?.metadata?.version?.major &&
				!props.fileData?.metadata?.version?.minor &&
				!props.fileData?.metadata?.version?.patch
			) {
				version = "00.00.00";
			} else {
				if (!isNaN(props.fileData?.metadata?.version?.major)) {
					version = props.fileData.metadata.version.major.toString() + ".";
				} else {
					version = "0.";
				}

				if (!isNaN(props.fileData?.metadata?.version?.minor)) {
					version = version + props.fileData.metadata.version.minor.toString() + ".";
				} else {
					version = version + "0.";
				}

				if (!isNaN(props.fileData?.metadata?.version?.patch)) {
					version = version + props.fileData.metadata.version.patch.toString();
				} else {
					version = version + "0";
				}
			}

			await Service.uploadFileMetadata(
				props.fileData?.metadata?.downloadInfo?.fileUniqueId,
				props.fileData?.fileName,
				props.fileData?.metadata?.title,
				props.fileData?.metadata?.description,
				props.fileData?.parentId ? Number(props.fileData.parentId) : null,
				props.fileData?.metadata?.fileType,
				version,
				props.fileData?.permission,
				props.fileData?.permittedCompanies,
				props.fileData?.permittedRoles
			);

			goBack();
		} catch (error) {
			props.addErrorAlert(handleErrorMessage(error));
			setUpdateInProgress(false);
		}
	};

	const handleCancelClick = async (): Promise<void> => {
		if (!props.fileId && props.fileData?.fileName) {
			try {
				await Service.cancelFileUpload(props.fileData.fileName);
			} catch (error) {
				props.addErrorAlert(handleErrorMessage(error));
			}
		}

		props.setFileManagementFile(null);

		goBack();
	};

	const submitButtonDisabled: boolean =
		(!props.fileId && !props.fileData?.metadata?.downloadInfo?.fileUniqueId) ||
		updateInProgress;

	return loading ? (
		<CircularProgress />
	) : (
		<Box className="fileManagement-form-container" display="flex" flexDirection="column">
			<Box
				className="fileManagement-form-title"
				display="flex"
				alignItems="center"
				flex={[1, 0]}
			>
				<UploadFileIcon className="icon" /> {title}
			</Box>

			<FileUploadSection
				editFileTitle={props.fileId}
				onUploadFinished={() => setFieldsVisible(true)}
			/>

			{fieldsVisible && (
				<>
					<FileMetadataSection />

					<FileAudienceSection />

					<FileTaxonomySection defaultSelectedId={viewParameters.parentId} />

					<Box
						className="fileManagement-formSection fileManagement-submitButtons"
						display="flex"
						justifyContent="flex-end"
					>
						<Button
							variant="contained"
							component="label"
							className="button button-cancel"
							onClick={handleCancelClick}
						>
							{Texts.Buttons.Cancel}
						</Button>

						<Button
							variant="contained"
							component="label"
							className={`button button-submit ${
								submitButtonDisabled ? "button-disabled" : ""
							}`}
							onClick={handleSubmitClick}
							disabled={submitButtonDisabled}
						>
							{props.fileId ? Texts.Buttons.Save : Texts.Buttons.Submit}

							{updateInProgress && <CircularProgress size={15} />}
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			...FileManagementStore.actionCreators,
			...AlertStoreActionCreators,
		},
		dispatch
	);
};

export default connect(
	(state: ApplicationState) => state.fileManagement,
	mapDispatchToProps
)(FileManagementContainer);

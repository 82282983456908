import { Box } from "@mui/system";
import * as React from "react";
import { Link } from "react-router-dom";
import SynamediaTitleImg from "../../images/synamedia-white.svg";
import SynamediaIconImg from "../../images/synamedia-icon-white.svg";
//import { ReactComponent as SynamediaTitleImg } from "../../images/synamedia-white.svg";
//import { ReactComponent as SynamediaIconImg } from "../../images/synamedia-icon-white.svg";
import { Routing } from "../../resources/Routes";
import Texts from "../../resources/Texts";
import DownloadDialog from "../dialogs/DownloadDialog";
import RoleRestrictedComponent from "../shared/formControls/RoleRestrictedComponent";
import LoginButton from "./LoginButton";
import ManagementToolsButton from "./ManagementToolsButton";
import ServicesMenu from "./ServicesMenu";
import "./NavMenu.scss";

const NavMenu: React.FunctionComponent<{}> = () => {
	const [downloadDialogOpened, setDownloadDialogOpened] = React.useState<boolean>(false);

	return (
		<header className="app-header">
			<Box
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				display="flex"
				className="header-wrapper"
			>
				<Box
					flexDirection="row"
					display="flex"
					alignItems="center"
					className="header-column header-left"
				>
					<Box className="header-link-box" display="flex" alignItems="center">
						<Link className="header-link link" to={Routing.getHomeViewUrl()}>
						  <img className="SynamediaIconImage" src={SynamediaIconImg} alt='Synamedia Logo' />
						  <img className="SynamediaTitleImage" src={SynamediaTitleImg} alt='Synamedia Wordmark' />
						</Link>
					</Box>

					<ServicesMenu />

					<RoleRestrictedComponent enabledRoles={["Engineer"]}>
						<ManagementToolsButton />
					</RoleRestrictedComponent>
				</Box>

				<Box
					flexDirection="row"
					display="flex"
					alignItems="center"
					className="header-column header-right"
				>
					<Box className="header-link-box" display="flex" alignItems="center">
						<a className="header-link link" href="https://www.synamedia.com/">
							{Texts.Navigation.ReturnToSynamedia}
						</a>
					</Box>

					<LoginButton />
				</Box>
			</Box>

			{downloadDialogOpened && (
				<DownloadDialog open={downloadDialogOpened} close={setDownloadDialogOpened} />
			)}
		</header>
	);
};

export default NavMenu;
